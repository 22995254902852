<template>
  <div>


    <v-card-title class="px-1"><p class="mb-0 text-h5">Preguntas frecuentes</p></v-card-title>

    <v-text-field
        class="mx-1"

        placeholder="Ingrese aquí palabras claves de su pregunta"
        prepend-inner-icon="mdi-magnify"
        clearable
        outlined
        dense
        :autofocus="!$vuetify.breakpoint.mobile"
        v-model.trim="busqueda"
    ></v-text-field>
    <v-card-text class="pt-0">
      <v-row justify="center" class="mb-10">
        <v-expansion-panels v-model="panelesAbiertos">
          <v-expansion-panel
              v-for="(data,i) in filtrarPreguntas"
              :key="i"
              :id="data.id"
              :class="{'blue lighten-5': panelesAbiertos === i}"
          >
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <div class=" align-center">
                  <p class="font-weight-regular" v-html="data.titulo" :class="{'mb-2': open, 'mb-0': !open}"></p>
                  <v-fade-transition>


                    <a v-if="open"
                       key="open"
                       :href="'#'+data.id"
                       class="mt-3"
                    >
                      {{ '#' + data.id }}
                    </a>
                  </v-fade-transition>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-html="data.cuerpo" class="mb-0 text-justify"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-card-text>

  </div>
</template>

<script>
export default {
  name: "InformacionViewPreguntasFrecuentes",
  data: () => ({
    panelesAbiertos: [],
    busqueda: "",
    dataTYC: [
      {
        id: "faq-1",
        titulo: "¿Qué son los Conservadores de Bienes Raíces?",
        cuerpo: 'El artículo 446 del Código Orgánico de Tribunales establece que "son Conservadores los ministros de fe encargados de los registros conservatorios de bienes raíces, de comercio, de minas, de accionistas de sociedades propiamente mineras, de asociaciones de canalistas, de prenda agraria, de prenda industrial, especial de prenda y demás que les encomienden las leyes". Su función es sumamente importante, por cuanto, es el Conservador quien estudia la legalidad y otorga validez a los títulos de propiedades, considerando la inscripción como requisito, prueba y garantía de la posesión.'
      },
      {
        id: "faq-2",
        titulo: "¿Cuál es la forma de operar del conservador de Bienes Raíces?",
        cuerpo: "<p>Los Conservadores de Bienes Raíces tienen un libro denominado \"Repertorio\" en el cual anotan todos los títulos que se presentan, además, llevan tres registros: Registro de Propiedad y Comercio:</p>" +
            "<p>En Propiedad se inscriben las transacciones de dominio (transferencia, transmisiones y adquisiciones por prescripción). En Comercio se inscriben las transacciones de sociedades de comercio y sus poderes.</p>" +
            "<p>Registro de Hipotecas y Gravámenes: En este registro se inscriben las hipotecas, los censos, los derechos de usufructo, uso y habitación, los fideicomisos, las servidumbres y otros gravámenes semejantes.</p>" +
            "<p class='mb-0'>Registro de Interdicciones y Prohibiciones de enajenar: En este registro se inscriben las interdicciones y prohibiciones de enajenar y todo impedimento o prohibición referentes a inmuebles que limite de cualquier modo el libre ejercicio del derecho de enajenar (embargo, cesión de bienes, litigios etc.).</p>"
      },
      {
        id: "faq-3",
        titulo: "¿Qué otras labores tiene a su cargo el Conservador de Bienes Raíces de Coquimbo?",
        cuerpo: "El Conservador, además de cumplir las obligaciones propias de su cargo, tiene la función de ser Archivero Judicial, lo cual significa que debe almacenar, custodiar y, a solicitud, remitir de vuelta los expedientes que los diversos juzgados de la zona le hacen llegar para su almacenaje, además de los protocolos notariales de los cuales, cuando corresponda, se pueden otorgar copias a los interesados."
      },
      {
        id: "faq-4",
        titulo: "¿Cómo se almacenan las inscripciones?",
        cuerpo: "<p>En el sistema registral chileno las inscripciones se practican mediante procesamiento de textos en medio papel los que van siendo agrupados en libros de hasta 350 fojas numeradas correlativamente. Estas inscripciones surten efecto legal desde el momento mismo en que el título que les da origen ha sido anotado en el Libro Repertorio, anotación que determina así la prioridad registral.</p>" +
            "<p class='mb-0'>Las subinscripciones se practican al margen de la respectiva inscripción, como así también las notas de referencia para vincular las inscripciones que digan relación con el bien respectivo y los derechos concernientes al mismo y a que se refieran otras inscripciones practicadas en el mismo registro u otros de los que por disposición de la ley están a cargo del Conservador. El archivo registral se complementa con los planos de subdivisión de inmuebles y de los acogidos a la normativa de los condominios.</p>"
      },
      {
        id: "faq-5",
        titulo: "¿Qué significan las palabras \"foja, número y año\"?",
        cuerpo: "Es la manera que, de acuerdo a la Ley, se singulariza una inscripción determinada en un Registro del Conservador. Esta mención queda certificada por el Conservador y anotada en la respectiva escritura cuando se practica una inscripción de dominio. Dicha mención es la que se requiere posteriormente para solicitar copias y certificados que tengan relación con la propiedad en cuestión."
      },
      {
        id: "faq-6",
        titulo: "¿Me sirve el número de Rol para realizar algún trámite en el Conservador de Bienes Raíces?",
        cuerpo: "No le sirve, ya que el número de Rol de una propiedad es una nomenclatura propia del Servicio de Impuestos Internos (SII). Solo sirve para efectos tributarios y NO ACREDITA DOMINIO."
      },
      {
        id: "faq-7",
        titulo: "¿Cómo puedo saber la foja, número y año que identifican a una propiedad en particular?",
        cuerpo: "<p>En primer lugar revise el título de dominio (escritura de compraventa, por ejemplo), en algún lugar debe haber un sello que dice que la propiedad fue inscrita en el \"REGISTRO DE PROPIEDAD a FOJAS xxxxx, bajo el NUMERO xxxxxx con fecha xxxx del mes de xxxxx del AÑO xxxxx\". Esa es la mención que busca.</p>" +
            "<p class='mb-0'>Si no tiene dicho documento, puede consultar en sección \"<a href='/indices'>Consulta de Indices</a>\" ingresando los apellidos del comprador. Si la propiedad no se encuentra aún ingresada en nuestros registros computacionales, deberá concurrir a nuestras oficinas para examinar los índices manualmente.</p>"
      },
      {
        id: "faq-8",
        titulo: "¿Cómo puedo hacer una inscripción de dominio?",
        cuerpo: "Necesita los siguientes Documentos: " +
            "<ul>" +
            "<li>Escritura de compraventa original</li>" +
            "<li>Formulario 2890 entregado en Notaria</li>" +
            "<li>Certificado de Avalúo Fiscal</li>" +
            "<li>Certificado de contribuciones al día</li>" +
            "</ul>"
      },
      {
        id: "faq-9",
        titulo: "¿Cómo puedo hacer una inscripción de una hipoteca?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Escritura de constitución de hipotecas originales</li>" +
            "<li>Certificado de Avalúo Fiscal</li>" +
            "<li>Certificado de contribuciones al día</li>" +
            "</ul>"
      },
      {
        id: "faq-10",
        titulo: "¿Cómo puedo hacer una inscripción de una prohibición?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Escritura de constitución de prohibición originales</li>" +
            "</ul>"
      },
      {
        id: "faq-11",
        titulo: "¿Cómo puedo hacer una inscripción de embargos, medidas precautorias e interdicciones?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Expedientes u oficio del tribunal</li>" +
            "</ul>"
      },
      {
        id: "faq-12",
        titulo: "¿Cómo puedo hacer una inscripción de posesión efectiva y especial de herencia?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Certificado posesión efectiva del Registro Civil</li>" +
            "<li>Certificado de Exención de impuesto a la herencia</li>" +
            "<li>Certificado de contribuciones al día</li>" +
            "<li>Certificado de Avalúo Fiscal</li>" +
            "</ul>"
      },
      {
        id: "faq-13",
        titulo: "¿Cómo puedo hacer una cancelación o alzamiento?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Expedientes u oficio del tribunal</li>" +
            "<li>Escritura de Alzamiento</li>" +
            "</ul>"
      },
      {
        id: "faq-14",
        titulo: "¿Cómo puedo hacer una inscripción de usufructo?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Escritura pública originales</li>" +
            "<li>Certificado de contribuciones al día</li>" +
            "<li>Certificado de Avalúo Fiscal</li>" +
            "</ul>"
      },
      {
        id: "faq-15",
        titulo: "¿Cómo puedo hacer una cancelación de usufructo vitalicio?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Certificado de defunción y citar foja, número y año del usufructo y título de dominio</li>" +
            "<li>Escritura pública de renuncia de usufructo en vida originales</li>" +
            "</ul>"
      },
      {
        id: "faq-16",
        titulo: "¿Cómo puedo hacer una inscripción de arrendamiento?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Escritura pública originales</li>" +
            "<li>Certificado de contribuciones al día</li>" +
            "<li>Certificado de Avalúo Fiscal</li>" +
            "</ul>"
      },
      {
        id: "faq-17",
        titulo: "¿Cómo puedo hacer una inscripción de reglamento de copropiedad?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Escritura pública originales</li>" +
            "<li>Certificado de contribuciones al día</li>" +
            "<li>Certificado de Avalúo Fiscal</li>" +
            "</ul>"
      },
      {
        id: "faq-18",
        titulo: "¿Cómo puedo hacer una inscripción de una nota al margen?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Escritura de rectificación o documento</li>" +
            "</ul>"
      },
      {
        id: "faq-19",
        titulo: "¿Cómo puedo archivar un plano?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>3 planos aprobados por DOM (Direccion de Obras Municipales) o SAG (Servicio Agricola y Ganadero) originales</li>" +
            "<li>Resolución municipal</li>" +
            "<li>Certificado de aprobación</li>" +
            "<li>Carta solicitud indicando foja, número y año del título de dominio</li>" +
            "</ul>"
      },
      {
        id: "faq-20",
        titulo: "¿Cómo puedo hacer una inscripción de sociedad anónima, E.I.R.L., Ltda., SPA y otras?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Escritura de constitución de sociedad original</li>" +
            "<li>Extractos o copias autorizadas</li>" +
            "</ul>" +
            "<p class='mb-0' style='padding-top: 5px'><b>Plazo 60 días y 30 días SPA </></p>"
      },
      {
        id: "faq-21",
        titulo: "¿Cómo puedo hacer una inscripción de modificación de sociedad?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Escritura de modificación original</li>" +
            "<li>Extractos citando foja, número y año de la constitución de sociedad</li>" +
            "</ul>" +
            "<p class='mb-0' style='padding-top: 5px'><b>Plazo 60 días </></p>"
      },
      {
        id: "faq-21",
        titulo: "¿Cómo puedo hacer una inscripción de poder, mandato y otros?",
        cuerpo: "Necesita los siguientes Documentos:" +
            "<ul>" +
            "<li>Escritura o acta de directorio</li>" +
            "<li>Citar foja, número y año de la constitución de sociedad</li>" +
            "</ul>"
      },
      {
        id: "faq-22",
        titulo: "¿Cómo puedo solicitar un certificado de Vigencia de sociedad o poder?",
        cuerpo: "Debe indicar foja, número y año del registro de comercio"
      },
      {
        id: "faq-23",
        titulo: "¿Cómo puedo solicitar una copia <b>con vigencia</b> de sociedad?",
        cuerpo: "Debe indicar foja, número y año del registro de comercio"
      },
      {
        id: "faq-24",
        titulo: "¿Cómo puedo solicitar una copia <b>sin vigencia</b> de sociedad?",
        cuerpo: "Debe indicar foja, número y año del registro de comercio"
      },
      {
        id: "faq-25",
        titulo: "¿Cómo puedo solicitar un certificado de dominio de una propiedad?",
        cuerpo: "Debe indicar foja, número y año del registro de propiedad"
      },
      {
        id: "faq-26",
        titulo: "¿Cómo puedo solicitar un certificado de hipotecas y gravámenes?",
        cuerpo: "Debe indicar foja, número y año del registro de propiedad"
      },
      {
        id: "faq-27",
        titulo: "¿Cómo puedo solicitar una copia <b>con vigencia</b> de una propiedad?",
        cuerpo: "Debe indicar foja, número y año del registro de propiedad"
      },
      {
        id: "faq-28",
        titulo: "¿Cómo puedo solicitar una copia <b>sin vigencia</b> de algún registro?",
        cuerpo: "Debe indicar foja, número y año del registro respectivo"
      }
    ],
  }),
  mounted() {
    this.openTab(this.$route.hash);
  },
  watch: {
    $route(to) {
      this.openTab(to.hash);
    }
  },

  computed: {
    filtrarPreguntas() {
      console.log()

      let filtered = this.dataTYC;
      if (this.busqueda !== "") {
        filtered = this.dataTYC.filter(f => {
          return f.titulo.toLowerCase().indexOf(this.busqueda.toLowerCase()) !== -1
        })
      }
      return filtered;
    }
  },
  methods: {
    openTab(hash) {
      const _hash = hash.replace("#", "");
      const index = this.dataTYC.findIndex((element) => {
        return element.id === _hash;
      })

      if (index !== -1) {
        this.panelesAbiertos = index;

        setTimeout(() => {
          this.$vuetify.goTo(hash, {
            easing: "easeOutCubic",
            offset: 150
          });
        }, 500);

      }
    }
  }
};
</script>
